import React from "react";
import BlockRichText from "./block-rich-text";
import BlockMedia from "./block-media";
import BlockQuote from "./block-quote";
import BlockSlider from "./block-slider";
import BlockGrid from "./block-grid";
import BlockYoutube from "./block-youtube";

const componentsMap = {
  ComponentSharedRichText: BlockRichText,
  ComponentSharedMedia: BlockMedia,
  ComponentSharedQuote: BlockQuote,
  ComponentSharedSlider: BlockSlider,
  ComponentSharedGridMedia: BlockGrid,
  ComponentSharedYoutube: BlockYoutube,
};

const Block = ({ block }) => {
  const Component = componentsMap[block.__typename];

  if (!Component) {
    return null;
  }

  return <Component data={block} />;
};

const BlocksRenderer = ({ blocks }) => {
  return (
    <div className="mb-3">
      {blocks.map((block, index) => (
        <Block key={`${index}${block.__typename}`} block={block} />
      ))}
    </div>
  );
};


export default BlocksRenderer;
