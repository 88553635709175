import React, { useState } from "react";
import ImgsViewer from "react-images-viewer";
import { normalizeUrl } from "../utils";

const BlockGrid = ({ data }) => {
  const [imageState, setImageState] = useState({
    image: [{ src: "" }],
    open: false,
  });

  const previewImage = (url) => {
    if (url) {
      setImageState({
        image: [{ src: normalizeUrl(url) }],
        open: true,
      });
    }
  };

  const onClose = () => setImageState({ ...imageState, open: false });

  if (!data || !Array.isArray(data.images)) return null;

  return (
    <div className="grid max-w-4xl gap-6 py-4 lg:grid-cols-3">
      {data.images.map((image) => {
          const item = image?.data?.attributes;
          return (
            <div
            key={`grid-media-${index}`}
            className="cursor-pointer"
            onClick={() => previewImage(item?.url)}
          >
            <LazyLoadImage
              className="aspect-video object-cover"
              effect="blur"
              height={"100%"}
              width={"100%"}
              alt={item?.alternativeText || item?.caption}
              src={normalizeUrl(item?.url)}
            />
          </div>
      )})}
      <ImgsViewer
        imgs={imageState.image}
        currImg={0}
        isOpen={imageState.open}
        onClickPrev={console.log}
        onClickNext={console.log}
        onClose={onClose}
        backdropCloseable={true}
        theme={{
          footerCount: {
            display: "none",
          },
        }}
      />
    </div>
  );
};

export default BlockGrid;
