import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"


const BlockRichText = ({ data }) => {
  return (
    <div className="max-w-4xl rich-text-content">
      <ReactMarkdown children={data.body} remarkPlugins={[remarkGfm]} />
    </div>
  );
};

export default BlockRichText;
