import React, { useEffect, useState } from "react";
import { FaFacebookSquare, FaTwitter } from "react-icons/fa";
import { HiLink } from "react-icons/hi";
import { RiWhatsappFill } from "react-icons/ri";
import { FormattedMessage } from "react-intl";
import Tag from "./common/tag";
import Motion from "./common/motion";

const ShareButton = ({ url, title }) => {
  const facebook = `https://facebook.com/sharer.php?u=${url}`;
  const twitter = `https://twitter.com/intent/tweet?text=${title} ${url}`;
  const whatsapp = `https://wa.me/?text=${title} ${url}`;
  const [copied, setCopied] = useState(false);

  const unsecuredCopyToClipboard = (text) => { 
    const textArea = document.createElement("textarea");
    textArea.value = text; document.body.appendChild(textArea); 
    textArea.focus(); textArea.select(); 
    try { 
      document.execCommand('copy') 
    } catch (err) { 
      console.error('Unable to copy to clipboard', err) 
    } finally {
      document.body.removeChild(textArea) 
    } 
  };

  const copyToClipboard = () => {
    const text = `${title} ${url}`;
    setCopied(true);
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard.writeText(text);
    } else {
      unsecuredCopyToClipboard(text);
    }
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  if (typeof window !== 'undefined') {
    return (
      <div className="mb-5">
        <h1 className="md:text-md mb-2 text-sm">
          <FormattedMessage id="share" defaultMessage={"Bagikan"} />
        </h1>
        <ul className="mb-6 flex flex-wrap items-center text-sm sm:mb-0">
          <li>
            <a href={facebook}  target={"_blank"} rel="noreferrer" aria-label="facebook">
              <FaFacebookSquare
                color="#4267B2"
                className="mr-4 h-5 w-5 p-0 text-white md:h-7 md:w-7"
              />
            </a>
          </li>
          <li>
            <a href={twitter} target={"_blank"} rel="noreferrer" aria-label="twitter">
              <FaTwitter
                color="#00acee"
                className="mr-4 h-5 w-5 p-0 text-white md:h-7 md:w-7"
              />
            </a>
          </li>
          <li>
            <a href={whatsapp} target={"_blank"} rel="noreferrer" aria-label="whatsapp">
              <RiWhatsappFill
                color="#25d366"
                className="mr-4 h-5 w-5 p-0 text-white md:h-7 md:w-7"
              />
            </a>
          </li>
          <li>
            <div className="cursor-pointer" onClick={copyToClipboard}>
              <Motion whileTap={{ scale: 0.9 }}>
                {copied ? (
                  <Motion
                    key="tag"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <Tag text={"link disalin"} className={`rounded-full`} />
                  </Motion>
                ) : (
                  <Motion
                    key="link-icon"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <HiLink
                      color="#333"
                      className={`mr-4 h-5 w-5 p-0 text-white md:h-7 md:w-7`}
                    />
                  </Motion>
                )}
              </Motion>
            </div>
          </li>
        </ul>
      </div>
    );
  }
  return null;
};

export default ShareButton;
