import React from "react";

const Headings = ({ title, authorName, publishedAt, date, views = 0 }) => {
  const viewsLabel = views > 0 ? `| dilihat ${views} kali` : "";
  return (
    <header className="">
      <h1 className="text-xl font-bold text-neutral-700">{title}</h1>
      <p className="text-xs">
        {date || publishedAt} oleh {authorName} {viewsLabel}
      </p>
    </header>
  );
};

export default Headings;
