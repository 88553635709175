import React, { useMemo } from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import Headings from "../../components/headings";
import BlocksRenderer from "../../components/blocks-renderer";
import ShareButton from "../../components/share-buttons";
import TitleGroup from "../../components/common/title-group";
import { formatGQLQuery } from "../../utils";
import queryMenu from "../../graphql/menu";
import {
  articleBySlug,
  popularArticles as popularArticlesQuery,
  articlesByCategory,
} from "../../graphql/article";
import Item from "../../components/common/item";
import Tag from "../../components/common/tag";
import contactAndAddress from "../../graphql/footer";

export default function Detail({ serverData, ...props }) {
  const { menu = [], article = {}, contact = {}, address = {}, url = "" } = serverData;
  const data = article?.attributes;

  const seo = {
    title: data?.title || "",
    description: data?.description || "",
    shareImage: data?.cover?.data?.attributes?.url || "",
    url
  };

  const authorName = useMemo(() => {
    if (!data?.createdBy || !data?.createdBy?.firstName) return "Admin";
    return `${data?.createdBy?.firstName || ""} ${data?.createdBy?.lastName || ""
      }`;
  }, [data]);

  return (
    <>
      <Seo {...seo} />
      <Layout menu={menu} contact={contact} address={address} {...props}>
        <main className="flex-1">
          <div className="container grid grid-cols-1 gap-0 overflow-hidden py-4 md:grid-cols-6 md:gap-10">
            <div className="col-span-4">
              <Headings
                title={data?.title}
                authorName={authorName}
                publishedAt={new Date(data?.date).toLocaleDateString("id-ID", {
                  dateStyle: "medium",
                })}
                views={data?.views}
              />
              <BlocksRenderer category={data?.category?.attributes?.slug} blocks={data?.blocks || []} />
              <ShareButton title={data?.title} url={props?.location?.href} />
            </div>
            <div className="col-span-2">
              <TitleGroup title="Artikel Terkait" />
              {serverData?.relatedArticles.map(({ attributes: item }, index) => {
                const url = item.url || `/detail/${item?.slug}`;
                const imageAlt =
                  item.cover?.data?.attributes?.alternativeText ||
                  item.cover?.data?.attributes?.caption ||
                  item.title;
                return (
                  <Item
                    key={`popular-article-item-${index}`}
                    containerClassName="my-3"
                    image={item.cover?.data?.attributes?.url}
                    imageAlt={imageAlt}
                    label={new Date(item.date).toLocaleDateString("id-ID", {
                      dateStyle: "medium",
                    })}
                    title={item.title}
                    url={url}
                  />
                );
              })}
              <TitleGroup title="Populer" containerClass="mb-3 mt-4" />
              {serverData?.popularArticles.map(({ attributes: item }, index) => {
                const url = item.url || `/detail/${item?.slug}`;
                const imageAlt =
                  item.cover?.data?.attributes?.alternativeText ||
                  item.cover?.data?.attributes?.caption ||
                  item.title;
                const subtitleText = `${item?.category?.data?.attributes?.name}`;
                const subtitle = (
                  <div className=" w-fit">
                    <Tag
                      text={subtitleText}
                      color="onSecondary"
                      className="text-fontPrimary"
                    />
                  </div>
                );
                return (
                  <Item
                    key={`popular-article-item-${index}`}
                    containerClassName="my-3"
                    image={item.cover?.data?.attributes?.url}
                    imageAlt={imageAlt}
                    label={new Date(item.date).toLocaleDateString("id-ID", {
                      dateStyle: "medium",
                    })}
                    title={item.title}
                    url={url}
                    subtitle={subtitle}
                  />
                );
              })}
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
}

export async function getServerData({ params }) {
  const options = {
    cache: "no-cache",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  const isoThirtyDaysAgo = thirtyDaysAgo.toISOString().toString();
  
  try {
    const response = await fetch(process.env.GRAPHQL_URL_SSR, {
      ...options,
      body: JSON.stringify({
        query: formatGQLQuery(
          [queryMenu, articleBySlug, popularArticlesQuery, contactAndAddress],
          [
            { key: "$articleBySlug", type: "String!" },
            { key: "$popularArticlesLimit", type: "Int!" },
            { key: "$popularArticlesMaxDate", type: "DateTime!" },
          ]
        ),
        variables: {
          articleBySlug: params?.slug,
          popularArticlesLimit: 7,
          popularArticlesMaxDate: isoThirtyDaysAgo,
        },
      }),
    });

    const { data } = await response.json();
    let article = data?.articleBySlug?.data;

    if (article && Array.isArray(article) && article.length > 0) {
      article = article[0];
      const relatedArticlesResponse = await fetch(process.env.GRAPHQL_URL_SSR, {
        ...options,
        body: JSON.stringify({
          query: formatGQLQuery(
            [articlesByCategory],
            [
              { key: "$articlesByCategory", type: "String!" },
              { key: "$articlesByCategoryLimit", type: "Int!" },
            ]
          ),
          variables: {
            articlesByCategory:
              article.attributes?.category?.data?.attributes?.slug,
            articlesByCategoryLimit: 7,
          },
        }),
      });
      const {
        data: {
          articlesByCategory: { data: relatedArticles },
        },
      } = await relatedArticlesResponse.json();
      return {
        props: {
          menu: data?.menu?.data,
          popularArticles: data?.popularArticles?.data,
          article,
          relatedArticles,
          contact: data?.contact?.data,
          address: data?.address?.data,
          url: `https://haji.kemenag.go.id/v5/detail/${params?.slug}`
        },
        status: 200,
      };
    } else {
      return {
        status: 404,
        headers: {},
        props: {},
      };
    }
  } catch (error) {
    return {
      status: 500,
    };
  }
}
